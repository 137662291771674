<!-- @Author: Yu_Bo 2023-09-06 17:20:39-->
<template>
    <div class="img_upload" :style="[style]">
        <el-upload 
            class="upload_btn" 
            ref="uploadBox"
            :accept="acceptTxt" 
            action="" 
            :show-file-list="false"
            :before-upload="beforeUpload" 
            :http-request="httpRequestMultiple">
            <div class="upload_loading" v-if="loading">
                <i class="el-icon-loading"></i>
            </div>
            <div style="width: 100%; height: 100%;" v-else>
                <slot></slot>
            </div>
        </el-upload>
    </div>
</template>

<script>
import { mixinUpload } from "@/mixin/upload"
export default {
    mixins: [mixinUpload],
    components: {},
    props: {
        width: {
            type: Number,
            defaul: 100
        },
        height: {
            type: Number,
            defaul: 100
        },
        type: {
            type: String,
            defaul: 'img_1'
        }
    },
    data() {
        return {
            loading: false,
        };
    },

    computed: {
        style() {
            return {
                width: this.width + 'px',
                height: this.height + 'px'
            }
        },
        // 选择限制
        acceptTxt() {
            var obj = this.uploadList.find(item => item.type == this.type)
            return obj.format
        },
        // 上传大小限制
        maxSize() {
            var obj = this.uploadList.find(item => item.type == this.type)
            return obj.maxSize
        },
        // 上传类型限制
        format() {
            var obj = this.uploadList.find(item => item.type == this.type)
            return obj.format.split(',')
        },
        // 上传类型提示
        formatTxt() {
            var obj = this.uploadList.find(item => item.type == this.type)
            return obj.formatTxt
        },
    },

    watch: {},

    created() { },

    mounted() { },

    methods: {
        // 上传限制
        beforeUpload(file) {
            console.log(file)
            const isLtSize = file.size / 1024 / 1024 < this.maxSize;
            const isType = this.format.includes(file.type);
            if (!isType) {
                this.$message.error('只能上传 ' + this.formatTxt + ' 格式!');
            }
            if (!isLtSize) {
                this.$message.error('上传文件大小不能超过 ' + this.maxSize + 'M!');
            }
            return isType && isLtSize;
        },

        // 上传
        httpRequestMultiple(uploader) {
            this.loading = true
            const formData = new FormData();
            formData.set('file', uploader.file);
            this.$publicApi.uploadReInfo(formData).then(res => {
                this.loading = false
                if (res.code === 1000) {
                    this.$emit('upload', res.result)
                } else {
                    this.$message.error(res.message);
                }
            })
        },
    }
}
</script>
<style lang='scss' scoped>
.img_upload {
    .upload_btn {
        width: 100%;
        height: 100%;
        border: 1px dashed #e8eaec;
        background-color: #f5f7fa;
        border-radius: 5px;
        ::v-deep .el-upload {
            width: 100%;
            height: 100%;
        }
    }
}
</style>