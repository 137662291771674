export const mixinUpload = {
    data() {
        return {
            uploadList: [
                { type: 'img_1', maxSize: 10, format: 'image/gif,image/png,image/jpg,image/jpeg', formatTxt: 'PNG,JPG,JPEG,GIF' },
				{ type: 'video_1', maxSize: 100, format: 'video/mp4', formatTxt: 'mp4' },
				{ type: 'audio_1', maxSize: 100, format: 'audio/wav,audio/mp3,audio/aac,audio/m4a,audio/x-m4a,audio/mpeg', formatTxt: 'wav,mp3,aac,m4a' },
            ]
        }
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
}
